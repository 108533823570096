import React from "react";

import { createAuthLink, AuthOptions, AUTH_TYPE } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";

import { Amplify } from "aws-amplify";

const appSyncConfig = {
  aws_project_region: process.env.REACT_APP_REGION,
  // // aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_REGION,
  // aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  // aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_REGION,
  oauth: {},
};
Amplify.configure(appSyncConfig);

const url = appSyncConfig.aws_appsync_graphqlEndpoint as string;
const region = appSyncConfig.aws_appsync_region as string;
const auth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => {
    // const sess = await API.Auth.currentSession();
    // return sess.getAccessToken().getJwtToken();
    return process.env.REACT_APP_GRAPHQL_TOKEN!;
  },
};
const idAuth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => {
    // const sess = await API.Auth.currentSession();
    // return sess.getIdToken().getJwtToken();
    return process.env.REACT_APP_GRAPHQL_TOKEN!;
  },
};

const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);
const idLink = ApolloLink.from([
  createAuthLink({ url, region, auth: idAuth }),
  createSubscriptionHandshakeLink({ url, region, auth: idAuth }, httpLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
export const clientWithIDToken = new ApolloClient({
  link: idLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
export const ApolloWrapper = (props: React.PropsWithChildren) => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
