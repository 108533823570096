import { AiOutlineClose } from 'react-icons/ai';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { PassTemplate } from './PassTemplatePicker';

interface PassTemplateDetailProps {
  passTemplate: PassTemplate;
  onCancel: () => void;
}

interface Pass {
  share: {
    url: string;
  };
}

export const PassTemplateDetail = ({
  passTemplate,
  onCancel,
}: PassTemplateDetailProps) => {
  //const [form] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [mutation] = useMutation<{ createPass: Pass }>(
    gql`
      mutation createPass($input: PassInput!) {
        createPass(input: $input) {
          share {
            url
          }
        }
      }
    `,
    {
      variables: {
        input: {
          templateId: passTemplate.id,
          parameters: {},
        },
      },
    }
  );

  const handleSubmit = async(e: any) => {
    e.preventDefault();
    setGenerating(true);
    try {
      const values = Object.fromEntries(new FormData(e.target as HTMLFormElement));
      const { data, errors } = await mutation({
        variables: {
          input: {
            templateId: passTemplate.id,
            parameters: JSON.stringify({ ...values }),
          },
        },
      });
      if (errors) {
        <div>
          {errors[0].message}
        </div>
      }
      if (data) {
        window.location.href = data?.createPass.share.url;
      }
    } catch (err: any) {
      <div>
          {err.message}
        </div>
    } finally {
      setGenerating(false);
    }
  }

  const closeModal = () => {
    onCancel();
  };

  return (
    <div className="fixed inset-0 bg-gray-400 bg-opacity-75 z-50 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg w-full max-w-lg">
        <div className="p-4">
          <div className='flex justify-between'>
            <h2 className="text-2xl font-medium">{passTemplate.name}</h2>
            <button onClick={closeModal}>
              <AiOutlineClose className="h-6 w-6 mb-6 text-gray-500 hover:text-black" />
            </button>
          </div>
          <form
            className="mt-4 space-y-4"
            onSubmit={handleSubmit}
          >
            {passTemplate.variables.map((v) => (
              <div key={v.name}>
                <label
                  htmlFor={v.name}
                  className="block text-gray-700 font-medium"
                >
                  {v.name}
                </label>
                <input
                  type="text"
                  id={v.name}
                  name={v.name}
                  className="bg-gray-200 appearance-none rounded w-full py-2 px-4 text-black leading-tight focus:border-violet-800"
                  required
                />
              </div>
            ))}
            <div>
              <button
                type="submit"
                className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gradient-to-r from-indigo-600 to-violet-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={generating}
              >
                {generating ? 'Generating...' : 'Generate Pass'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
