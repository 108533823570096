import { useState } from "react";
import "./App.css";
import { PassTemplateDetail } from "./pages/PassTemplateDetail";
import { PassTemplate, PassTemplatePicker } from "./pages/PassTemplatePicker";

function App() {
  const [passTemplate, setPassTemplate] = useState<PassTemplate>();

  return (
    <div>
      <div>
        {passTemplate ? (
          <PassTemplateDetail
            passTemplate={passTemplate}
            onCancel={() => setPassTemplate(undefined)}
          />
        ) : (
          <PassTemplatePicker
            onSelect={(pt) => {
              setPassTemplate(pt);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default App;
